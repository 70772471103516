import React from "react";
import {Element} from 'react-scroll'
import Menu from "../../components/menu";

// Auth
export default function FreeZoneTeamPage() {
	return(
        <Element name="team">
            <section className="blocks-overlay" id="team">
                <div className="flex justify-between items-center">
                    <div className=" container flex justify-center ">
                        <div className="w-500">
                            <h1 className="f-s-55 text-marlo-white">Команда<span className="text-marlo-red">.</span></h1>
                            <p className="f-s-16 text-marlo-t-gray">На сайте представлен состав на момент 11.03.2023 года</p>
                            <div className="block-person mt-20">
                                <p className="f-s-16 text-marlo-white">Демьян Пашков</p>
                                <span className="f-s-14 text-marlo-t-gray">Генеральный директор</span>
                            </div>
                            
                        </div>
                    </div>
                    <div className="container flex justify-end mt-20">
                        <img src="../img/auth/team.svg" alt="about" width="600px"/>
                    </div>
                </div>
            </section>
        </Element>
        ) 
};
