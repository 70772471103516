import React from "react";
import Menu from "../../components/menu";
import {Link} from 'react-scroll'


// Auth
export default function FreeZoneCareerPage() {
  return(
        <Link to="career">
        <section className="blocks-overlay" name="career">
            <div className="flex justify-between items-center">
                <div className=" container flex justify-center ">
                   <div className="w-500">
                        <h1 className="f-s-55 text-marlo-white">Карьера<span className="text-marlo-red">.</span></h1>
                        <p className="f-s-16 text-marlo-t-gray">Мы ищем талантливых и увлеченных людей, чтобы присоединиться к нашей команде. Если вы стремитесь к совершенствованию каждый день и очень преданы своей работе, мы ждём от вас оффер, даже если вы не нашли подходящую вакансию!</p>
                        <div className="overlay mt-20">
                            <div className="w-500 my-10">
                                <h2 className="f-s-30 text-marlo-white">Верстальщик</h2>
                                <p className="f-s-14 text-marlo-t-gray">HTML, SCSS/SASS, Tailwind. Семантическая, кросс-браузерная и адаптивная верстка, оптимизация и рефакторинг старого кода</p>
                            </div>
                            <div className="w-500 my-10">
                                <h2 className="f-s-30 text-marlo-white">JS разработчик</h2>
                                <p className="f-s-14 text-marlo-t-gray">Базовые знания JS на уровне junior. Знание и понимание базовых хуков, работа с axios запросами, приветствуется работа с React</p>
                            </div>
                            <div className="w-500 my-10">
                                <h2 className="f-s-30 text-marlo-white">Специалист по безопасности</h2>
                                <p className="f-s-14 text-marlo-t-gray">Знание server и web уязвимостей, составление ландшафта угроз, создание Honeypot, работа с реестром cve</p>
                            </div>
                            <div className="w-500 my-10">
                                <h2 className="f-s-30 text-marlo-white">Графический дизайнер</h2>
                                <p className="f-s-14 text-marlo-t-gray">Отличное владение графическими программами, такими как Adobe Illustrator, Adobe Photoshop , Figma. Умение генерировать собственные идеи, а также воплощать идеи по поставленному ТЗ</p>
                            </div>
                            <div className="w-500 my-10">
                                <h2 className="f-s-30 text-marlo-white">Графический дизайнер</h2>
                                <p className="f-s-14 text-marlo-t-gray">Отличное владение графическими программами, такими как Adobe Illustrator, Adobe Photoshop , Figma. Умение генерировать собственные идеи, а также воплощать идеи по поставленному ТЗ</p>
                            </div>
                        </div> 
                        <button className="w-full rounded-2xl bg-marlo-red hover:bg-marlo-d-red p-4 my-6 text-marlo-white">Оставить заявку</button>
                   </div>
                </div>
                <div className="container flex justify-end items-center mt-20">
                    <div className="w-full bg-marlo-block p-6 block-report">
                        <h1 className="text-marlo-white  f-s-35 text-center">Присоединяйся в команду<span className="text-marlo-red">.</span></h1>
                        <div className="w-full flex  justify-center">
                            <div className="w-1/2">
                                <div className="w-full p-6">
                                <p className="text-marlo-white f-s-20">Контактные данные: </p>
                                <input type="text" placeholder="Имя" className="w-full inp-report" required/>
                                <input type="tel" placeholder="Номер телефона" className="w-full inp-report" required/>
                                <input type="email" placeholder="Email" className="w-full inp-report" required/>
                                <p className="text-marlo-white f-s-20 mt-20">Выбери себе вакансию:</p>
                                <input type="checkbox" id="js" className="mt-20"/> <label htmlFor="js" className="text-marlo-white f-s-14">JS разработчик</label> <br />
                                <input type="checkbox" id="web" className="mt-10"/> <label htmlFor="web" className="text-marlo-white f-s-14">Верстальщик</label> <br />
                                <input type="checkbox" id="graf-dis" className="mt-10"/> <label htmlFor="graf-dis" className="text-marlo-white f-s-14">Графический дизайнер</label> <br />
                                <input type="checkbox" id="spec" className="mt-10"/> <label htmlFor="spec" className="text-marlo-white f-s-14">Спец по безопасности</label> <br />
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="w-full m-6">
                                    <p className="text-marlo-white">Информация о себе:  </p>
                                    <input type="text" placeholder="Ссылка на GitHub аккаунт " className="w-full inp-report" required/>
                                    <textarea placeholder="Краткая инфорация о себе" className="w-full textarea-report" required/>
                                </div>
                                <div className="w-full m-6">
                                    <p className="text-marlo-white">Резюме:</p>
                                    <button className="w-full rounded-2xl bg-marlo-red hover:bg-marlo-d-red p-4 my-6 text-marlo-white">Загрузить</button>
                                </div>
                            </div>
                        </div> 
                        <button className="w-full rounded-2xl bg-marlo-red hover:bg-marlo-d-red p-4 my-6 text-marlo-white">Оставить заявку</button>
                    </div>
                </div>
            </div>
        </section>
        </Link>
        ) 
};