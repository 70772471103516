import React from "react";
import { Link } from "react-router-dom";
import {Link as LinkComponens} from 'react-scroll'
import Menu from "../../components/menu";

// Auth
export default function FreeZoneAboutPage() {
	return(
            <section className="blocks-overlay" id="about">
                <div className="flex justify-between items-center">
                    <div className=" container flex justify-center ">
                    <div className="w-500">
                            <h1 className="f-s-55 text-marlo-white">О нас<span className="text-marlo-red">.</span></h1>
                            <p className="f-s-16 text-marlo-white">Marlo — мультиприложение, которое содержит множество различных полезных сервисов. <br /> <br />
                            Наша миссия — сделать удобный прорывной продукт, который станет твоим основным сервисом на каждый день. <br /> <br />
                            Только представь, что самое лучшее из популярных сервисов и приложений будет воплощено всего лишь в одном - Marlo.  <br /> <br />
                            Дизайн и функциональность Marlo — отражение современных требований рынка. <br /> <br />
                            Мы собираем обратную связь, дорабатываем и добавляем нужные мелочи.</p>
                            <div className="">
                                <p className="f-s-12 my-6 text-marlo-t-gray">Мы в соц. сетях:</p>
                                <div className="container flex ">
                                    {[
                                    ['../img/auth/telegram.svg', 'https://t.me/marlo_official'],
                                    ['../img/auth/vk.svg', 'https://vk.com/marlo_official'],
                                    ].map(([title,url]) =>(
                                        <Link to={url} key={url}><img src={title} className="mr-4" alt="items" /></Link>
                                    ))}
                                </div>
                            </div>
                    </div>
                    
                    </div>
                    <div className="container flex justify-end mt-20">
                        <img src="../img/auth/about.svg" alt="about" width="600px"/>
                    </div>
                </div>
            </section>
        ) 
};
