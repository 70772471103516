import React from "react";
import { Link } from "react-router-dom";
import Menu from "../../components/menu";
import FreeZoneAboutPage from "./about-us";
import FreeZoneTeamPage from "./team";
import FreeZoneCareerPage from "./career";
import {Link as LinkComponens} from 'react-scroll'


// Auth
export default function FreeZoneLoginPage() {
	return(
        <div >
            
            <Menu/>
            <LinkComponens to="main">
                <section className="flex justify-between items-center blocks-overlay">
                    <div className=" container flex justify-center items-center">
                    <div className="w-500">
                            <span className="f-s-22  text-marlo-white">Marlo — первый мультиэпп в РФ</span>
                            <h1 className="f-s-55 text-marlo-white">Войти в аккаунт<span className="text-marlo-red">.</span></h1>
                            <p className="f-s-20 text-marlo-t-gray">Ещё нет аккаунта? <Link 
                            // to="/Registration"
                            > <span className="underline text-marlo-red">Зарегистрироваться</span> </Link></p>
                            <div className="flex my-8 justify-between">
                                <div className="w-full">
                                    <label htmlFor="" className="f-s-12 text-marlo-white">Email или Телефон</label> <br />
                                    <input type="text" className="inp my-2" placeholder="Введите Email или Телефон"/>
                                </div>
                                <div className="w-auto">
                                    <label htmlFor="" className="f-s-12 text-marlo-white">Пароль</label>
                                    <input type="password" className="inp my-2" placeholder="Введите пароль"/>
                                </div>
                            </div> 
                            <Link 
                            // to="/recover-mail"
                             className="f-s-14 underline text-marlo-white">Забыли пароль?</Link>
                            <button className="w-full rounded-2xl bg-marlo-red hover:bg-marlo-d-red p-4 my-6 text-marlo-white">Войти</button>
                            <div className=" flex justify-between">
                                <a href="" className="underline f-s-14 text-marlo-white">Политика конфиденциальности</a>
                                <p className=" text-marlo-white f-s-14">LLC "Marlo Group"</p>
                            </div>Link
                    </div>
                    </div>
                    <div className="container flex justify-end">
                        <img src="../img/auth/login.svg" alt="login" width="600px"/>
                    </div>
                </section>
            </LinkComponens>
            {/*  */}

            {/*  */}
            <FreeZoneAboutPage/>
            {/*  */}

            {/*  */}
             <FreeZoneTeamPage/>
            {/*  */}

            {/*  */}
            <FreeZoneCareerPage/>
            {/*  */}

           
        </div>
        ) 
};
