import React from "react";
// import { Link, useLocation,  } from 'react-router-dom'
import {Link, animateScroll as scroll} from 'react-scroll'

// Menu
export default function Menu() {


	return(
        <div className="w-wg fixed bg-marlo-l-black  d-a-c flex text-marlo-white ">
            <nav className="container flex  justify-center items-center transition-all">
            {[
                ['О нас', 'about'],
                ['Команда', 'team'],
                ['Карьера', 'career'],
            ].map(([title,url]) =>(
                <Link
                    to={url}
                    smooth spy 
                    className="block-a"
                    offset={-100}
                    activeClass="block-a block-a-hover"

                    duration={500}
                    isDynamic={true}
                    key={url}>{title}</Link>
                    
            ))}
            </nav>
            <div className="container flex m-6 justify-center">
                  {[
                ['../img/logo.svg', '/'],
            
            ].map(([title,url,img]) =>(
                <Link onClick={e => scroll.scrollToTop()} key={url}><img src={title} key={url} alt="logo" /> </Link>
            ))}
            </div>
            <div className="container flex  m-6 justify-center"></div>   {/* не удалять, нужен для выранивания меню четко по центру */}
        </div>
        ) 
};
