import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import Login from './pages/free_zone/login';
import About from './pages/free_zone/about-us';
import FreeZoneTeamPage from './pages/free_zone/team';
import FreeZoneCareerPage from './pages/free_zone/career';
import FreeZoneRegistrationPage from './pages/free_zone/registration';
import FreeZoneMailPage from './pages/free_zone/Mail';
import FreeZoneNumberPage from './pages/free_zone/Number';
import FreeZonePasswordPage from './pages/free_zone/Password';
import FreeZoneRecoverMailPage from './pages/free_zone/recover-mail';
import FreeZoneRecoverPasswordPage from './pages/free_zone/recover-password';
import FreeZoneRecoverLoginPage from './pages/free_zone/recover-login';
import PrivateZoneNewsPage from './pages/private_zone/news';
import PrivateZoneHeaderPage from './components/header';
import PrivateZoneMenuLeftPage from './components/menuLeft';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  // Link,
} from "react-router-dom";



const router = createBrowserRouter([
  { path: "/",
    element: ( <Login />),
  },
  // {
  //   path: "about",
  //   element: ( <About />),
  // },
  // {
  //   path: "team",
  //   element: ( <FreeZoneTeamPage />),
  // },
  // {
  //   path: "career",
  //   element: ( <FreeZoneCareerPage />),
  // },
  // {
  //   path: "registration",
  //   element: ( <FreeZoneRegistrationPage />),
  // },
  // {
  //   path: "mail",
  //   element: ( <FreeZoneMailPage />),
  // },
  // {
  //   path: "number",
  //   element: ( <FreeZoneNumberPage />),
  // },
  // {
  //   path: "password",
  //   element: ( <FreeZonePasswordPage />),
  // },
  // {
  //   path: "recover-mail",
  //   element: ( <FreeZoneRecoverMailPage />),
  // },
  // {
  //   path: "recover-password",
  //   element: ( <FreeZoneRecoverPasswordPage />),
  // },
  // {
  //   path: "recover-login",
  //   element: ( <FreeZoneRecoverLoginPage />),
  // },
  // {
  //   path: "news",
  //   element: ( <PrivateZoneNewsPage />),
  // },
  // {
  //   path: "header",
  //   element: ( <PrivateZoneHeaderPage />),
  // },
  // {
  //   path: "menu-left",
  //   element: ( <PrivateZoneMenuLeftPage />),
  // },
  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
